import React from 'react';
// import { LOADER_IMG } from '../../utils/imageUtils';
import LOADER_IMG from "../../assets/Images/Spinner.svg";

class Loader extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    let title = '';
    if (this.props.title) {
      title = this.props.title;
    }
    return (
      <div className={`loaderbg ${this.props.loaded ? 'hide' : 'show'}`}>
        <div className="loader_content">
          <div className="loaderText">{title}</div>
          <div className="loaderimg">
            <img src={LOADER_IMG} title="" alt="" />
          </div>
        </div>
      </div>
    );
  }
}

export default Loader;
