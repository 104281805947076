import React from "react";
import { withRouter } from 'react-router-dom';
// import {
//   GAScreenTitle,
//   Title,
//   GAEventName,
// } from "../../shared/Localization/LocalizedString";
// import translate from "../../shared/Localization/translate";
import Hold from '../../assets/Images/icons-ic-success.svg';

class ThankYou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: props.location && props.location.data ? props.location.data.price : 'X',
    };
  }

  render() {
    return (
      <>
        <div className="payment_pg">
          <div className="container">
            <div className="thanks_wrapper">
              <img src={Hold} alt="img" />
              <div className="thanks_title">Hold Processed</div>
              <div className="thanks_text">
                {" "}
                Your card has been authorized for a payment of ${this.state.price}. This payment
                will be processed in case of loss or damage beyond the repair to
                the wheel.
              </div>
            </div>
          </div>
        </div>
    </>
    );
  }
}

export default withRouter(ThankYou);
