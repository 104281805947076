import React from "react";
import { withRouter } from "react-router-dom";
import Loader from "../CommonComponent/Loader";

import Cleave from "cleave.js/dist/cleave-react";
import PaymentAPI from "../../shared/NetworkLayer/UrgentlyAPIs/APISignature/PaymentAPI";
import * as apiUtils from "../CommonComponent/ApiUtils";
import notification from "../../shared/Utils/notification";
import { UrgentlyAPIs } from "../../shared";
import APIConfigs from "../../configs/AppConfigs";

class CardDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParam: new URLSearchParams(props?.location?.search),
      savedCardInfo: [],
      paymentCardClass: "fa-credit-card",
      securityToken: null,
      recievedData: [],
      fetchingCardDetail: true,
      savedCardId: null,
      cvvLength: 3,
      cardType: "",
      cardNumber: "",
      cardNumberEntered: false,
      dateEntered: false,
      cvvEntered: false,
      zipcodeEntered: false,
      addingCard: false,
      date: "",
      cvv: "",
      zipcode: "",
      grange: false,
      serviceType: null,
      paymentAmount: null,
      distance: null,
      totalCoverageDistance: null,
      restrictDelete: false,
      price: "",
      name: "",
      desc: "",
      isLoading: false,
    };

    this.cardNumberLength = {
      amex: 17,
      jcb: 19,
      visa: 19,
      mastercard: 19,
      discover: 19,
      other: 19,
    };
  }

  componentDidMount() {
    const qparams = this.state.queryParam;
    let transactionCode;
    if (qparams.get("transactionCode") !== null) {
      transactionCode = qparams.get("transactionCode");
      this.verifyTransactionCode(transactionCode);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.cardNumber.length !== this.state.cardNumber.length) {
      const cardType =
        this.state.cardType !== "" ? this.state.cardType : "other";
      if (this.cardNumberLength[cardType] === this.state.cardNumber.length) {
        this.setState({ cardNumberEntered: true });
      } else {
        this.setState({ cardNumberEntered: false });
      }
    }
  }

  verifyTransactionCode = async (code) => {
    PaymentAPI.verifyTransactionCode.setParam(code);
    let reqURL = PaymentAPI.verifyTransactionCode.getRQURL;
    try {
      this.setState({ isLoading: true });
      const tokenInfo = await apiUtils.verifyTransactionCode(reqURL);
      if (tokenInfo?.error?.toLowerCase() === "invalid token") {
        this.setState({ isLoading: false });
        this.props.history.replace({
          pathname: '/expired',
          action: 'Expired',
        });
      } else if (
        tokenInfo?.error?.toLowercase() === "transaction code not found"
      ) {
        this.setState({ isLoading: false });
        this.props.history.replace({
          pathname: '/expired',
          action: 'Expired',
        });
      } else {
        APIConfigs.AUTH_CODE = tokenInfo.internalAccessToken;
        APIConfigs.REFRESH_TOKEN = tokenInfo.refreshToken;
        const jobNumber = tokenInfo.jobNumber;
        PaymentAPI.getJobDetails.setParam(jobNumber);
        PaymentAPI.checkJobAuthorization.setParam(jobNumber);
        PaymentAPI.authorizeAndPay.setJobNumber(jobNumber);
        let jobAuthUrl = PaymentAPI.checkJobAuthorization.getRQURL;
        let url = PaymentAPI.getJobDetails.getRQURL;
        const caseDetail = await UrgentlyAPIs.GET(url, false);
        const price = await caseDetail.service.customerPrice;
        const jobAuth = await UrgentlyAPIs.GET(jobAuthUrl, false);
        if (jobAuth) {
          this.setState({ isLoading: false });
          this.props.history.replace({
            pathname: "/thank",
            data: { price: price },
          });
        } else {
          APIConfigs.ACCOUNT_ID = caseDetail.personalInfo.accountId;
          const name = caseDetail.service.contactName;
          const desc = caseDetail.service.description;
          this.setState({ price, name, desc, isLoading: false });
        }
      }
    } catch (e) {
      console.error("error", e);
      this.setState({ isLoading: false });
      this.notifyError(this.verifyTransactionCode);
    }
  };

  onCreditCardTypeChanged = (type) => {
    const cardType = ["visa", "amex", "mastercard", "discover", "jcb"];
    if (cardType.indexOf(type) == -1) {
      let cardType = "other";
      if (type === "unknown") {
        cardType = type;
      }
      this.setState({ paymentCardClass: "fa-credit-card", cardType: cardType });
    } else {
      const cardObj = { paymentCardClass: "fa-cc-" + type, cardType: type };
      if (type === "amex") {
        cardObj.cvvLength = 4;
      } else {
        cardObj.cvvLength = 3;
      }
      if (this.state.cvv.length > 0) {
        cardObj.cvv = "";
        cardObj.cvvEntered = false;
      }
      this.setState(cardObj);
    }
  };

  onCreditCardChange = (event) => {
    let invalidCard = false;
    if (event.target.value.length > 15 && this.state.cardType === "unknown") {
      invalidCard = true;
    }
    this.setState({ cardNumber: event.target.value, invalidCard: invalidCard });
  };

  onDateChange = (event) => {
    //this.setState({ date: event.target.value });
    if (event.target.value.length >= 5) {
      let expiry = event.target.value.split("/");
      var today, expDate;
      var exMonth = expiry[0] - 1;
      var exYearInput = parseInt(expiry[1]);
      var exYear = 0;//"20" + expiry[1];

      //handle 2 or 4 digit years
      if (exYearInput >= 0 && exYearInput < 100) {
        exYear = 2000 + exYearInput;
      } else if (exYearInput >= 1900 && exYearInput < 2200) {
        //debugger
        exYear = exYearInput;
      } else {
        this.setState({ dateEntered: false, invalidExpiry: true, date: null });
        return;        
      }
      today = new Date();
      expDate = new Date();
      expDate.setFullYear(exYear, exMonth, 1);

      if (expDate > today) {
        this.setState({ dateEntered: true, invalidExpiry: false, date: expDate });
      } else {
        this.setState({ dateEntered: false, invalidExpiry: true, date: null });
      }
    } else {
      this.setState({ dateEntered: false, invalidExpiry: false, date: null });
    }
  };
  onCvvChange = (event) => {
    this.setState({ cvv: event.target.value });
    if (this.state.cardType == "amex") {
      if (event.target.value.length == 4) {
        this.setState({ cvvEntered: true });
      } else {
        this.setState({ cvvEntered: false });
      }
    } else {
      if (event.target.value.length == 3) {
        this.setState({ cvvEntered: true });
      } else {
        this.setState({ cvvEntered: false });
      }
    }
  };
  onZipcodeChange = (event) => {
    this.setState({ zipcode: event.target.value });
    if (event.target.value.length == 5) {
      this.setState({ zipcodeEntered: true });
    } else {
      this.setState({ zipcodeEntered: false });
    }
  };
  checkValueEntered = () => {
    if (
      this.state.cardNumberEntered &&
      !this.state.invalidCard &&
      this.state.dateEntered &&
      this.state.cvvEntered &&
      this.state.zipcodeEntered
    ) {
      return true;
    } else {
      return false;
    }
  };
  submitDetail = () => {
    this.setState({ isLoading: true });
    const params = {
      acctId: APIConfigs.ACCOUNT_ID,
      name: this.state.name,
      desc: this.state.desc,
      cvv: this.state.cvv,
    };
    PaymentAPI.authorizeAndPay.setUrlParams(params);
    const body = {
      number: this.state.cardNumber.replace(/ +/g, ""),
      cardType: this.state.cardType,
      month: this.state.date.getMonth() + 1,
      year: this.state.date.getFullYear(),
      postalCode: this.state.zipcode,
      amount: this.state.price,
    };
    const sessionId = this.state.cardNumber.replace(/ +/g, "");
    UrgentlyAPIs.POST(
      PaymentAPI.authorizeAndPay.getRQURL,
      body,
      true,
      sessionId
    )
      .then((response) => {
        this.setState({ isLoading: false });
        if (response.data.length > 0 && response.data[0].id) {
          this.props.history.replace({
            pathname: "/thank",
            data: { price: this.state.price },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
        this.notifyError(this.submitDetail);
      });
  };

  notifyError = (callback, params) => {
    let title = "Error";
    let msg = "We are having trouble right now. \n\n Please try again.";
    notification.notifyErrorAndTryAgain(title, msg, callback, params);
  }

  render() {
    return (
      <div className="payment_pg">
        <Loader
          loaded={!this.state.isLoading}
          color="#000"
          title="Loading..."
        />
        <div className="container pr0 pl0">
          <div className="payment_wrap">
            <div className="title" data-testid="payment-title">
              Credit Card Hold
            </div>
            <div className="desc">
              To service your vehicle, we will need to collect payment
              information in case spare is not returned.
            </div>
            <div className="payment_item_detail">
              <div className="two_column_center">
                <div data-testid="spare-tires">Spare Tires (1) </div>
                <div>${this.state.price}</div>
              </div>
              <div className="two_column_center total">
                <div>Total Due</div>
                <div data-testid="total-cost">${this.state.price}</div>
              </div>
            </div>
            <form className="payment_form new_textbox">
              <div className="form-group">
                <label>CARD NUMBER</label>
                <span className="icon_textbox">
                  <Cleave
                    className="form-control"
                    // onInit={this.onCreditCardInit}
                    value={this.state.cardNumber}
                    options={{
                      creditCard: true,
                      onCreditCardTypeChanged: this.onCreditCardTypeChanged,
                    }}
                    type="tel"
                    id="cardNum"
                    placeholder=""
                    onChange={this.onCreditCardChange}
                    autoFocus
                    data-testid="number"
                    required
                  />
                  <i className={`fa ${this.state.paymentCardClass}`} />
                  {/* <img src={ic_visacard} alt="img" /> */}
                </span>
                {this.state.invalidCard ? (
                  <span className="error_msg">Invalid Card number</span>
                ) : (
                  ""
                )}
              </div>
              <div className="two_column_center">
                <div className="form-group pr10">
                  <label>EXP. DATE</label>
                  <Cleave
                    className="form-control"
                    // value=""
                    options={{
                      date: true,
                      datePattern: ["m", "Y"],
                    }}
                    id="expdate"
                    placeholder=" "
                    type="tel"
                    onChange={this.onDateChange}
                    required
                    data-testid="expiry"
                  />
                  {this.state.invalidExpiry ? (
                    <span className="error_msg exp">Invalid Exp. date</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-group pr10 cvv_textbox">
                  <label>CVV</label>
                  <Cleave
                    className="form-control"
                    options={{
                      numericOnly: true,
                      delimiter: "",
                    }}
                    id="cvv"
                    type="tel"
                    placeholder=" "
                    onChange={this.onCvvChange}
                    maxLength={this.state.cvvLength}
                    value={this.state.cvv}
                    data-testid="cvv"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Zip Code</label>
                  <Cleave
                    className="form-control"
                    options={{
                      numericOnly: true,
                      delimiter: "",
                      blocks: [5],
                    }}
                    id="zipcode"
                    placeholder=" "
                    type="tel"
                    onChange={this.onZipcodeChange}
                    data-testid="zip"
                    required
                  />
                </div>
              </div>
              <div className="mt10">
                <button
                  data-testid="submit"
                  type="button"
                  className={`black_btn ${
                    this.checkValueEntered() ? "" : "disabled"
                  }`}
                  onClick={this.submitDetail}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CardDetails);
