import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import TIME_EXPIRED from '../../assets/Images/Icon_Time_Expired.svg';

function LinkExpired(props) {
  return (
    <>
      {props.location.action ? (
        <div className="thanks_page">
          <div className="thanks_box">
              <>
                <img src={TIME_EXPIRED} title="" alt="" className="mb10" />
                <div className="thanks_hd" data-testid="title">
                  Link Expired
                </div>
                <div className="thanks_info" data-testid="body">
                  This page is no longer available. 
                </div>
              </>  
          </div>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

export default withRouter(LinkExpired);
