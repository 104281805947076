export default {
  authorizeBenefit: {
    Signature: "/coverage",
    decisionTreeOutcome: null,
    serviceType: null,
    customerId: null,
    customerPhone: null,

    setParam(customerId, customerPhone, decisionTreeOutcome, serviceType) {
      this.customerId = customerId;
      this.customerPhone = customerPhone;
      this.decisionTreeOutcome = decisionTreeOutcome;
      this.serviceType = serviceType;
    },

    get getParam() {
      return {
        customer: { phone: this.customerPhone },
        service: {
          decisionTreeOutcome: this.decisionTreeOutcome,
          serviceType: this.serviceType,
        },
      };
    },

    get getRQURL() {
      return this.Signature + "/" + this.customerId + "/authorize-benefit";
    },
  },
  authorizePayment: {
    Signature: "/customer-payments/saved-card/",
    id: null,
    acctId: null,
    desc: null,
    amount: null,
    get get() {
      return (
        this.Signature +
        this.id +
        "/authorize?amount=" +
        this.amount +
        "&desc=" +
        this.desc +
        "&acctId=" +
        this.acctId
      );
    },
  },

  linkPaymentWithJob: {
    Signature: "/customer-jobs/",
    jobNumber: null,
    id: null,
    type: null,
    get get() {
      return (
        this.Signature +
        this.jobNumber +
        "/payment-instrument?payment-instrument-id=" +
        this.id +
        "&type=" +
        this.type
      );
    },
  },
  getSavedCardInfo: {
    Signature: "/customer-account-members",
    memberId: null,

    set(memberId) {
      this.memberId = memberId;
    },

    get get() {
      return this.Signature + "/" + this.memberId + "/saved-cards";
    },
  },
  verifyTransactionCode: {
    Signature: "/customer-jobs/verify/token/",
    code: "",

    setParam(code) {
      this.code = code;
    },
    get getRQURL() {
      return this.Signature + this.code;
    },
  },
  getJobDetails: {
    Signature: "/customer-jobs/",
    jobNumber: "",

    setParam(jobNumber) {
      this.jobNumber = jobNumber;
    },
    get getRQURL() {
      return this.Signature + this.jobNumber;
    },
  },
  checkJobAuthorization: {
    Signature: "/customer-payments/card-authorization/job/",
    jobNumber: "",

    setParam(jobNumber) {
      this.jobNumber = jobNumber;
    },
    get getRQURL() {
      return this.Signature + this.jobNumber;
    },
  },
  authorizeAndPay: {
    Signature: "/customer-payments/card-authorization/job/",
    jobNumber: "",
    acctId: "",
    name: "",
    desc: "",
    cvv: "",

    setJobNumber(jobNumber) {
      this.jobNumber = jobNumber;
    },
    setUrlParams(params) {
      this.acctId = params.acctId;
      this.name = params.name;
      this.desc = params.desc;
      this.cvv = params.cvv;
    },
    get getRQURL() {
      return `${this.Signature}${this.jobNumber}?acctId=${this.acctId}&name=${this.name}
      &desc=${this.desc}&cvv=${this.cvv}&channel=Customer`;
    },
  },
  getSystemConfig: {
    Signature: "/system-configs/keys?",
    env: "",
    apiVersion: "",

    setParam(env, apiVersion) {
      this.env = env;
      this.apiVersion = apiVersion;
    },
    get getRQURL() {
      return this.Signature + "env=" + this.env + "&v=" + this.apiVersion;
    },
  },
  findByToken: {
    Signature: "/customers/find-by-token?requestToken=",
    auth: "",

    setParam(auth) {
      this.auth = auth;
    },
    get getRQURL() {
      return this.Signature + this.auth;
    },
  },
};
