import React, { Suspense } from "react";
// import { Provider } from "react-redux";
import "./App.scss";
// import * as Sentry from "@sentry/react";
import { Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import Loader from "./screens/CommonComponent/Loader";
import CardDetails from "./screens/CardDetails/CardDetails";
import Expired from "./screens/LinkExpired/LinkExpired";
import ThankYou from "./screens/Thanks/Thanks";
import "./App.scss";

class App extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
        <Suspense
          fallback={<Loader color="#000" loaded={false} title={"Retrieving"} />}
        >
          <Switch>
            <Route exact path="/" children={<CardDetails />} />
            <Route exact path="/expired" children={<Expired />} />
            <Route exact path="/thank" children={<ThankYou />} />
          </Switch>
        </Suspense>
    );
  }
  // }
}

export default App;
