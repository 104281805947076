import { UrgentlyAPIs } from '../../shared';
// import APIConfigs from '../../../../customer-vehicle-determination/src/configs/AppConfigs';
// import JobAPIConfigs from '../../configs/AppConfigs';

export const verifyTransactionCode = async (url) => {
  try {
    const tokenInfo = await UrgentlyAPIs.GET(url, false);
    if (tokenInfo.status !== 400) return tokenInfo;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
