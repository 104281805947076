export default {
  ACCESS_TOKEN: '7d60c652-617b-4904-94d0-d1e9617fd249',
  API_VERSION: process.env.REACT_APP_API_VERSION,
  INDUSTRY_TYPE: process.env.REACT_APP_INDUSTRY_TYPE,
  APP: process.env.REACT_APP_APP_TYPE,
  HOST_URL: process.env.REACT_APP_HOST_URL,
  AUTH_URL: process.env.REACT_APP_AUTH_URL,
  AUTH_CODE: '',
  REFRESH_TOKEN: '',
  ENVIROMENTUL:
    process.env.REACT_APP_BUILD == 'dev' || process.env.REACT_APP_BUILD == 'qa'
      ? 'dev'
      : 'prod',
  CAMPAIGN_PARTNER: '',
  TRACKER: {
    ENV_ID: process.env.REACT_APP_BUILD === 'dev' ? '2' : '1',
    CLIENT: process.env.REACT_APP_TRACKER_CLIENT,
    CUST_RADIUS: 35,
    INDUSTRY: process.env.REACT_APP_INDUSTRY_TYPE,
    APP: 'UrgentlyHTML5',
    API_VERSION: process.env.REACT_APP_API_VERSION,
    API_KEY: '029ccd43-a401-4b9b-b99f-b919f29a32ee',
    TRACKER_CONFIG_URL: process.env.REACT_APP_TRACKER_CONFIG_URL,
    API_URL: '',
  },
  getConfigUrlTracker() {
    return (
      this.TRACKER.TRACKER_CONFIG_URL +
      '?v=' +
      this.TRACKER.API_VERSION +
      '&env=' +
      this.ENVIROMENTUL
    );
  },
  getConfigKeysUrlTracker() {
    return (
      this.TRACKER.TRACKER_CONFIG_URL +
      '/keys?v=' +
      this.TRACKER.API_VERSION +
      '&env=' +
      this.ENVIROMENTUL
    );
  },
  getCancelReasonUrl() {
    return (
      this.TRACKER.TRACKER_CONFIG_URL +
      '/customer/cancel/reasons?v=' +
      this.TRACKER.API_VERSION +
      '&env=' +
      this.ENVIROMENTUL
    );
  },
  setPartner() {
    if (
      this.ENVIROMENTUL === 'dev' &&
      process.env.REACT_APP_PARTNER &&
      process.env.REACT_APP_PARTNER == 'volvo'
    ) {
      this.CAMPAIGN_PARTNER = process.env.REACT_APP_PARTNER.toLowerCase();
    }
  },
};
