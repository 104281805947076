import ApiConfigs from '../../../configs/AppConfigs';

export default {
  POST(_apiname, _apiParam, includeHost = true, logSessionId) {
    return new Promise((resolve, reject) => {
      let apiURL;
      if (includeHost) {
        apiURL = ApiConfigs.HOST_URL + _apiname;
      } else {
        apiURL = _apiname;
      }
      var authCode = ApiConfigs.AUTH_CODE;
      let customHeader = {
        Accept: 'application/json',
        Product: 'Customer-App',
        'Content-Type': 'application/json',
        //apikey: ApiConfigs.ACCESS_TOKEN,
      };
      if (authCode != '') {
        customHeader['Authorization'] = 'Bearer ' + authCode;
      }
      if (logSessionId) {
        customHeader['session'] = logSessionId;
      }

      fetch(apiURL, {
        method: 'POST',
        headers: customHeader,
        body: JSON.stringify(_apiParam),
      }).then(function (response) {
        let status = response.status;
        if (response.status >= 200 && response.status < 300) {
          if (response.headers.get('Content-Type') != null)
            return resolve(response.json());
          else {
            return resolve(response.blob());
          }
        } else {
          if (
            response.headers.get('Content-Type') != null &&
            response.headers.get('Content-Type') ===
              'application/json;charset=UTF-8'
          ) {
            return reject(response.json());
          } else if (response.headers.get('Content-Type') != null) {
            return reject(response);
          } else {
            return reject(response.blob());
          }
        }
      });
    });
  },

  GET(method, isConfigCall = false, logSessionId) {
    let apiURL = ApiConfigs.HOST_URL + method;
    var authCode = ApiConfigs.AUTH_CODE;
    let customHeader = {
      Accept: 'application/json',
      Product: 'Customer-App',
      'Content-Type': 'application/json',
      //"auth-token": ApiConfigs.ACCESS_TOKEN,
    };
    if (isConfigCall == false && authCode != '') {
      customHeader['Authorization'] = 'Bearer ' + authCode;
    }
    if (logSessionId) {
      customHeader['session'] = logSessionId;
    }
    return fetch(apiURL, {
      method: 'GET',
      headers: customHeader,
    }).then(function (response) {
      let status = response.status;
      if (response.status >= 200 && response.status <= 400) {
        if (response.headers.get('Content-Type') != null)
          if (
            response.headers.get('Content-Type') == 'text/plain;charset=UTF-8'
          ) {
            return Promise.resolve(response);
          } else {
            return Promise.resolve(response.json());
          }
        else {
          return Promise.resolve(response.blob());
        }
      } else {
        if (response.headers.get('Content-Type') != null) {
          return Promise.reject(response.json());
        } else {
          return Promise.reject(response.blob());
        }
      }
    });
  },

  PUT(method, _apiParam, logSessionId) {
    let apiURL = ApiConfigs.HOST_URL + method;
    var authCode = ApiConfigs.AUTH_CODE;
    let customHeader = {
      Accept: 'application/json',
      Product: 'Customer-App',
      'Content-Type': 'application/json',
      //apikey: ApiConfigs.ACCESS_TOKEN
    };
    if (authCode != '') {
      customHeader['Authorization'] = 'Bearer ' + authCode;
    }
    if (logSessionId) {
      customHeader['session'] = logSessionId;
    }
    return fetch(apiURL, {
      method: 'PUT',
      headers: customHeader,
      body: JSON.stringify(_apiParam),
    }).then(function (response) {
      let status = response.status;
      if (response.status >= 200 && response.status < 300) {
        if (response.headers.get('Content-Type') != null)
          return Promise.resolve(response.json());
        else {
          return Promise.resolve(response.blob());
        }
      } else {
        if (response.headers.get('Content-Type') != null) {
          return Promise.reject(response.json());
        } else {
          return Promise.reject(response.blob());
        }
      }
    });
  },
};
